import type { ReactElement } from 'react';
import { useEffect, useRef, useState } from 'react';

import { Group, Stack, Text, Title } from '@mantine/core';

import { IconExternalLink } from '@tabler/icons-react';

import { capitalizeFirstLetter } from '../../utils';
import { BuyerLogo } from '../BuyerLogo.tsx/BuyerLogo';
import { Tooltip } from '../Tooltip/Tooltip';

type SizeParam = 'sm' | 'md' | 'lg';

export type TenderTitleProps = {
  buyerOriginalName: string;
  logoURL?: string;
  buyerId?: number;
  tenderTitle: string;
  withTooltip?: boolean;
  withExternalLinkIcon?: boolean;
  size?: SizeParam;
};

export const TenderTitle = ({
  buyerOriginalName,
  logoURL,
  tenderTitle,
  buyerId,
  withTooltip = false,
  withExternalLinkIcon = false,
  size = 'md',
}: TenderTitleProps) => {
  const titleRef = useRef<HTMLDivElement>(null);
  const [isTruncated, setIsTruncated] = useState(false);

  const buyerPageLinkProps = buyerId
    ? {
        w: 'fit-content',
        onMouseDown: (e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation(),
        onClick: () => {
          window.open(`/buyer/${buyerId}`, '_blank');
        },
        sx: {
          borderBottom: `1px solid transparent`,
          ':hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
          },
        },
      }
    : {};

  useEffect(() => {
    // Check if the content is truncated
    const checkTruncation = () => {
      const element = titleRef.current;
      if (element) {
        // Compare scrollWidth and clientWidth to determine truncation
        setIsTruncated(element.scrollWidth > element.clientWidth);
      }
    };

    checkTruncation();

    // Optionally, you can also listen to window resize events to re-check for truncation
    window.addEventListener('resize', checkTruncation);

    return () => window.removeEventListener('resize', checkTruncation);
  }, [tenderTitle]);

  const sizeConfig = sizeConfigs(capitalizeFirstLetter(tenderTitle.toLowerCase()), titleRef)[size];

  return (
    <Tooltip content={tenderTitle} isVisibile={withTooltip && isTruncated} width={620}>
      <Stack spacing={sizeConfig.verticalSpacing} w="100%">
        <Group noWrap spacing="02" c={sizeConfig.buyerColor} {...buyerPageLinkProps}>
          <BuyerLogo
            size={sizeConfig.buyerLogo.size}
            radius={sizeConfig.buyerLogo.radius}
            logoURL={logoURL}
            buyerId={buyerId}
          />
          <Text variant="sm" fw={sizeConfig.buyerWeight} lineClamp={1} tt="capitalize">
            {buyerOriginalName.toLowerCase()}
          </Text>
          {buyerId && withExternalLinkIcon && <IconExternalLink size={16} />}
        </Group>

        {sizeConfig.titleElement}
      </Stack>
    </Tooltip>
  );
};

type SizeConfig = {
  avatarBorderRadius: 'xs' | 'sm';
  buyerColor: 'primary.6' | 'primary.7';
  buyerWeight: 400 | 500;
  buyerLogo: {
    radius: 'xs' | 'sm';
    size: 'xs' | 'sm' | 'md' | 'lg';
  };
  titleElement: ReactElement;
  verticalSpacing: 0 | '0' | '02';
};

type SizeConfigs = Record<SizeParam, SizeConfig>;

const sizeConfigs = (title: string, ref: React.RefObject<HTMLDivElement>): SizeConfigs => ({
  sm: {
    avatarBorderRadius: 'xs',
    buyerColor: 'primary.7',
    buyerWeight: 400,
    buyerLogo: {
      radius: 'sm',
      size: 'sm',
    },
    verticalSpacing: 0,
    titleElement: (
      <Text ref={ref} variant="sm" fw="500" c="gray.9" truncate>
        {title}
      </Text>
    ),
  },
  md: {
    avatarBorderRadius: 'xs',
    buyerColor: 'primary.7',
    buyerWeight: 400,
    buyerLogo: {
      radius: 'sm',
      size: 'sm',
    },
    verticalSpacing: '0',
    titleElement: (
      <Title ref={ref} order={5} c="gray.9" lineClamp={3}>
        {title}
      </Title>
    ),
  },
  lg: {
    avatarBorderRadius: 'sm',
    buyerColor: 'primary.6',
    buyerWeight: 500,
    buyerLogo: {
      radius: 'xs',
      size: 'md',
    },
    verticalSpacing: '02',
    titleElement: (
      <Title ref={ref} order={3} c="gray.9">
        {title}
      </Title>
    ),
  },
});
