import type { BrDoc } from './BrDocs';
import type { DataPointSource } from './DataPointSource';

export type BrBuyerEvaluation = {
  id: number;
  improvement?: string;
  criterias: BrCriteria[];
  docs: BrDoc[];
  brAnalysisStatus: BrAnalysisStatus;
};

export type BRUserRetrievalAssessment =
  | 'UNVERIFIED'
  | 'PRESENT_BUT_NOT_FOUND'
  | 'NOT_PRESENT'
  | 'ASSESSMENT_NOT_ALLOWED';

export type BrCriteria = {
  id: number;
  category: string;
  evaluationId: number;
  description: string;
  retrievedFromTechnicalSubmission?: string;
  source: string;
  pageNumber: number;
  originalChunkExtract: string;
  technicalSubmissionSources?: DataPointSource[];
  userRetrievalAssessment: BRUserRetrievalAssessment;
  userSubmissionAssessment: BrUserSubmissionAssessment;
};

export enum BrAnalysisStatus {
  WAITING = 'WAITING',
  PENDING = 'PENDING',
  RUNNING = 'RUNNING',
  DONE = 'DONE',
  FAILED = 'FAILED',
}

export enum BrUserSubmissionAssessment {
  TO_EVALUATE = 'TO_EVALUATE',
  TO_REWORK = 'TO_REWORK',
  TO_ADD = 'TO_ADD',
  SATISFYING = 'SATISFYING',
}
