import { Buffer } from 'buffer';
import { DateTime } from 'luxon';

export function formatCurrency(value?: number) {
  if (!value) {
    return null;
  }

  const formatter = new Intl.NumberFormat('fr-FR', {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 1,
  });
  if (value >= 1000000) {
    return formatter.format(value / 1000000) + 'M€';
  } else if (value >= 1000) {
    return formatter.format(value / 1000) + 'k€';
  } else {
    return formatter.format(value) + '€';
  }
}

export function formatNumber(value: number) {
  const parts = value.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  const formattedNumber = parts.join(',');
  return formattedNumber;
}

export function formatDate(date?: string) {
  if (!date) {
    return null;
  }
  return DateTime.fromISO(date).toLocaleString();
}

export function formatDateAsText(date?: string, dayOnly = false, truncateMonth = true) {
  if (!date) {
    return DateTime.now().toLocaleString({
      day: '2-digit',
      month: truncateMonth ? 'short' : 'numeric',
      year: dayOnly ? undefined : 'numeric',
    });
  }
  return DateTime.fromISO(date).toLocaleString({
    day: '2-digit',
    month: truncateMonth ? 'short' : 'long',
    year: dayOnly ? undefined : 'numeric',
  });
}

export function extractDomainFromURL(url: string) {
  const urlObj = new URL(url);
  const parts = urlObj.hostname.split('.');
  const tld = parts.pop();
  const domain = parts.pop();
  return `${domain}.${tld}`;
}

export function extractDomainFromEmail(email: string) {
  const domain = email.split('@')[1];
  const parts = domain.split('.');
  if (parts.length <= 2) {
    return domain;
  }
  return parts.slice(-2).join('.');
}

type AsyncMapper<T, U> = (value: T, index: number, array: T[]) => Promise<U>;

export async function asyncMap<T, U>(array: T[], mapper: AsyncMapper<T, U>): Promise<U[]> {
  const promises: Promise<U>[] = array.map(mapper);
  return await Promise.all(promises);
}

export function removeTrailingZeros(string: string) {
  return string.replace(/0+$/, '');
}

export function removeEmoji(string: string) {
  const regexEmoji =
    /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi;
  return string.replace(regexEmoji, '');
}

export function encodeObjectToBase64<T extends object>(obj: T): string {
  const locationData = JSON.stringify(obj);
  const encodedData = Buffer.from(locationData, 'utf-8').toString('base64');
  const urlSafeEncodedData = encodeURIComponent(encodedData);
  return urlSafeEncodedData;
}

export function decodeObjectFromBase64<T extends object>(encodedData: string): T | null {
  const urlSafeEncodedData = decodeURIComponent(encodedData);
  try {
    const decodedData = Buffer.from(urlSafeEncodedData, 'base64').toString('utf-8');
    return JSON.parse(decodedData) as T;
  } catch (e) {
    return null;
  }
}

export function normalizeString(string: string) {
  return string
    .normalize('NFD') // Normalize to decomposed form (letters are separate from accents)
    .replace(/[\u0300-\u036f]/g, '') // Remove accents
    .toLowerCase();
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
