import { Stack, Title, Text, Group, Box } from '@mantine/core';

import { IconX } from '@tabler/icons-react';

import { Button } from '../../UI/Button/Button';

import { BrUserSubmissionAssessment } from '../../entities/BrBuyerEvaluation';
import { AssessmentButton } from '../notices/InstantAnalysis/AssessmentButton';
import { AssessmentCircularGraph } from '../notices/InstantAnalysis/AssessmentCircularGraph';
import { useAssessmentRecap } from './useAssessmentRecap.hook';

type EvalCardProps = {
  brTenderId: number;
  filter: BrUserSubmissionAssessment | null;
  setFilter: (filter: BrUserSubmissionAssessment | null) => void;
};

export const EvalCard = ({ brTenderId, filter, setFilter }: EvalCardProps) => {
  const { assessmentRecap } = useAssessmentRecap(brTenderId);
  const assessmentForCircularGraph = {
    blockingPoint: assessmentRecap.TO_REWORK,
    attentionPoint: assessmentRecap.TO_ADD,
    positivePoint: assessmentRecap.SATISFYING,
    unAssessed: assessmentRecap.TO_EVALUATE,
    neutralPoint: 0,
  };

  return (
    <Group
      align="flex-start"
      noWrap
      spacing="06"
      px="06"
      py="05"
      sx={{
        borderRadius: `12px 12px 0px 0px`,
        background: `linear-gradient(180deg, #F7F7F8 0%, rgba(255, 255, 255, 0.00) 100%)`,
      }}
    >
      <AssessmentCircularGraph assessmentRecap={assessmentForCircularGraph} />
      <Stack spacing="04" align="flex-start">
        <Group spacing="03" noWrap>
          <Title order={5} c="dark.9">
            Évaluation
          </Title>
          <Box h="16px" sx={theme => ({ borderLeft: `1px solid ${theme.colors.gray[2]}` })} />
          <Text variant="sm" fw={500} c="dark.9">
            {`${assessmentRecap.total} attentes acheteur détectées`}
          </Text>
          <Box h="16px" sx={theme => ({ borderLeft: `1px solid ${theme.colors.gray[2]}` })} />
          <Text variant="sm" fw={400} c="dark.9">
            Filter par
          </Text>
        </Group>
        <Group spacing="03">
          {assessmentRecap.TO_EVALUATE > 0 && (
            <AssessmentButton
              assessment={BrUserSubmissionAssessment.TO_EVALUATE}
              count={assessmentRecap.TO_EVALUATE}
              selected={filter === BrUserSubmissionAssessment.TO_EVALUATE}
              onClick={() => setFilter(BrUserSubmissionAssessment.TO_EVALUATE)}
              withFilledBadge
            />
          )}
          <AssessmentButton
            assessment={BrUserSubmissionAssessment.TO_ADD}
            count={assessmentRecap.TO_ADD}
            selected={filter === BrUserSubmissionAssessment.TO_ADD}
            onClick={() => setFilter(BrUserSubmissionAssessment.TO_ADD)}
            withFilledBadge
          />
          <AssessmentButton
            assessment={BrUserSubmissionAssessment.TO_REWORK}
            count={assessmentRecap.TO_REWORK}
            selected={filter === BrUserSubmissionAssessment.TO_REWORK}
            onClick={() => setFilter(BrUserSubmissionAssessment.TO_REWORK)}
            withFilledBadge
          />
          <AssessmentButton
            assessment={BrUserSubmissionAssessment.SATISFYING}
            count={assessmentRecap.SATISFYING}
            selected={filter === BrUserSubmissionAssessment.SATISFYING}
            onClick={() => setFilter(BrUserSubmissionAssessment.SATISFYING)}
            withFilledBadge
          />
          {filter && <ClearFilterButton onClick={() => setFilter(null)} />}
        </Group>
      </Stack>
    </Group>
  );
};

const ClearFilterButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Button
      variant="subtle"
      c="gray.8"
      size="sm"
      onClick={onClick}
      leftIcon={<IconX size={16} stroke={1.5} />}
    >
      Réinitialiser
    </Button>
  );
};
