import type { ReactNode } from 'react';

import type { FeatureFlag } from '../entities/FeatureFlags';
import { useFeatureFlags } from './FeatureFlagsAndActiveUser.context';

type FeatureFlagGuardProps = {
  children: ReactNode;
  feature: FeatureFlag;
};

export default function FeatureFlagGuard({ children, feature }: FeatureFlagGuardProps) {
  const isFeatureEnabled = useFeatureFlags(feature);
  if (!isFeatureEnabled) {
    return null;
  }
  return children;
}

export const withFeatureFlag = (children: React.ReactNode, featureFlag?: FeatureFlag) => {
  return featureFlag ? (
    <FeatureFlagGuard feature={featureFlag}>{children}</FeatureFlagGuard>
  ) : (
    children
  );
};
