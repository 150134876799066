import { useMutation } from '@tanstack/react-query';

import { getBrTender, updateUserRetrievalAssessment } from '../../api/magellan';
import { queryClient } from '../../api/queryClient';
import type {
  BRUserRetrievalAssessment,
  BrUserSubmissionAssessment,
} from '../../entities/BrBuyerEvaluation';
import type { BrTender } from '../../entities/BrTender';
import { useAssessmentRecap } from './useAssessmentRecap.hook';

export function useRetrievalAssessment(brTenderId: number) {
  const { updateAssessmentRecap } = useAssessmentRecap(brTenderId);
  const mutationFn = ({
    criteraId,
    userRetrievalAssessment,
    userSubmissionAssessment,
  }: {
    criteraId: number;
    userRetrievalAssessment?: BRUserRetrievalAssessment;
    userSubmissionAssessment?: BrUserSubmissionAssessment;
  }) => {
    return updateUserRetrievalAssessment(brTenderId, criteraId, {
      userRetrievalAssessment,
      userSubmissionAssessment,
    });
  };
  const mutation = useMutation({
    mutationFn,
    onSuccess: (_data, variables) => {
      return queryClient.setQueryData([getBrTender.name, brTenderId], (brTender: BrTender) => {
        if (!brTender) {
          return brTender;
        }
        return {
          ...brTender,
          brBuyerEvaluation: brTender.brBuyerEvaluation.map(evaluation => {
            return {
              ...evaluation,
              criterias: evaluation.criterias.map(criteria => {
                if (criteria.evaluationId === variables.criteraId) {
                  updateAssessmentRecap(
                    criteria.userSubmissionAssessment,
                    variables.userSubmissionAssessment ?? criteria.userSubmissionAssessment,
                  );
                  return {
                    ...criteria,
                    userRetrievalAssessment:
                      variables.userRetrievalAssessment ?? criteria.userRetrievalAssessment,
                    userSubmissionAssessment:
                      variables.userSubmissionAssessment ?? criteria.userSubmissionAssessment,
                  };
                }
                return criteria;
              }),
            };
          }),
        };
      });
    },
  });
  return mutation;
}
