import type { FileWithPath } from '@mantine/dropzone';

import { useMutation } from '@tanstack/react-query';

import { createTechnicalSubmission, getBrTender } from '../../api/magellan';
import { queryClient } from '../../api/queryClient';

export function useCreateTechnicalSubmission() {
  const mutationFn = ({ brTenderId, file }: { brTenderId: number; file: FileWithPath }) =>
    createTechnicalSubmission(brTenderId, file);

  const mutation = useMutation({
    mutationFn,
    onSettled: async (_data, _error, variable) => {
      return await queryClient.invalidateQueries({
        queryKey: [getBrTender.name, variable.brTenderId],
      });
    },
  });
  return mutation;
}
