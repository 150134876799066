import { useSuspenseQuery } from '@tanstack/react-query';

import { getBrAssessmentsRecap } from '../../api/magellan';
import { queryClient } from '../../api/queryClient';
import type { BrUserSubmissionAssessment } from '../../entities/BrBuyerEvaluation';

export function useAssessmentRecap(tenderId: number) {
  const queryKey = ['getBrAssessmentsRecap', tenderId];
  const {
    data: { assessmentRecap },
  } = useSuspenseQuery({
    queryKey,
    queryFn: () => getBrAssessmentsRecap(tenderId),
  });

  const updateAssessmentRecap = (
    prevAssessment: BrUserSubmissionAssessment,
    newAssessment: BrUserSubmissionAssessment,
  ) => {
    queryClient.setQueryData(queryKey, (prev: any) => {
      return {
        assessmentRecap: {
          ...prev.assessmentRecap,
          [prevAssessment]: prev.assessmentRecap[prevAssessment] - 1,
          [newAssessment]: prev.assessmentRecap[newAssessment] + 1,
        },
      };
    });
  };
  return { assessmentRecap, updateAssessmentRecap };
}
