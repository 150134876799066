import { useEffect } from 'react';

import { useSuspenseQuery } from '@tanstack/react-query';

import { getBrTender } from '../../api/magellan';
import { queryClient } from '../../api/queryClient';
import { BrAnalysisStatus } from '../../entities/BrBuyerEvaluation';
import type { BrTender } from '../../entities/BrTender';

export function useAnswer(tenderId: number): BrTender {
  const { data: tender } = useSuspenseQuery({
    queryKey: [getBrTender.name, tenderId],
    queryFn: () => getBrTender(tenderId),
  });

  const evaluationsStatus = tender.brBuyerEvaluation.map(evaluation => evaluation.brAnalysisStatus);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (
        evaluationsStatus.includes(BrAnalysisStatus.PENDING) ||
        evaluationsStatus.includes(BrAnalysisStatus.WAITING) ||
        evaluationsStatus.includes(BrAnalysisStatus.RUNNING) ||
        tender.criteriasExtractionStatus === BrAnalysisStatus.PENDING ||
        tender.criteriasExtractionStatus === BrAnalysisStatus.WAITING ||
        tender.criteriasExtractionStatus === BrAnalysisStatus.RUNNING
      ) {
        queryClient.invalidateQueries({ queryKey: [getBrTender.name, tenderId] });
      } else {
        clearInterval(intervalId);
      }
    }, 5000);

    return () => clearInterval(intervalId);
  }, [evaluationsStatus, tender.criteriasExtractionStatus, tenderId]);
  return tender;
}
