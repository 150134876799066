import { forwardRef } from 'react';

import { Group, Select, Text } from '@mantine/core';

import type { AssessmentStatus } from '../../../entities/AssessmentStatus';
import type { BrUserSubmissionAssessment } from '../../../entities/BrBuyerEvaluation';
import { AssessmentBadge } from './AssessmentBadge';

type DataItem = {
  value: string;
  label: string;
  assessment: AssessmentStatus | BrUserSubmissionAssessment | null;
};

type AssessmentDropdownProps<Status extends AssessmentStatus | BrUserSubmissionAssessment> = {
  data: DataItem[];
  selectedAssesment: AssessmentStatus | BrUserSubmissionAssessment | null;
  onSelect: (assesment: Status | null) => void;
  withoutBorder?: boolean;
};

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  label: string;
  value: string;
  assessment: AssessmentStatus | BrUserSubmissionAssessment | null;
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ label, assessment, ...others }: ItemProps, ref) => {
    return (
      <Group {...others} ref={ref}>
        <AssessmentBadge assessment={assessment} isSelected />
        <Text fw={400}>{label}</Text>
      </Group>
    );
  },
);

export function AssessmentDropdown<Status extends AssessmentStatus | BrUserSubmissionAssessment>({
  data,
  selectedAssesment,
  onSelect,
  withoutBorder = false,
}: AssessmentDropdownProps<Status>) {
  const handleChange = (value: string) => {
    const selected = data.find(item => item.value === value);
    if (selected) {
      onSelect(selected.assessment as Status);
    }
  };

  return (
    <Select
      w={186}
      icon={<AssessmentBadge assessment={selectedAssesment} isSelected />}
      defaultValue={selectedAssesment}
      onChange={handleChange}
      data={data}
      itemComponent={SelectItem}
      styles={theme => ({
        input: {
          border: withoutBorder ? 'none' : `1px solid ${theme.colors.gray[3]}`,
          borderRadius: theme.radius.md,
          color: theme.colors.gray[9],
        },
        icon: {
          paddingLeft: '6px',
        },
      })}
    />
  );
}
