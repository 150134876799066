import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Divider, Group, Stack, Text, Title, Image } from '@mantine/core';

import { IconArrowRight } from '@tabler/icons-react';

import { Button } from '../../UI/Button/Button';
import TenderStatusBadge from '../../UI/TenderStatusBadge/TenderStatusBadge';
import { TenderTitle } from '../../UI/TenderTitle/TenderTitle';

import EmptyToAnalyseIllustration from '../../assets/decisionList/empty_to_analyse_illustration.svg';
import type { BrTender } from '../../entities/BrTender';
import { CreateBrTenderModal } from './CreateBrTenderModal';
import { useAnswers } from './useAnswers.hook';

export function AnswersPageContent() {
  const { tenders } = useAnswers();

  if (!tenders.length) {
    return <EmptyPlaceholder />;
  }

  return (
    <Stack
      py="04"
      px="05"
      sx={theme => ({
        borderRadius: theme.radius.md,
        boxShadow: theme.shadows.sm,
        border: `1px solid ${theme.colors.gray[9]}14`,
        background: 'white',
      })}
    >
      {tenders.map(tender => (
        <Answers key={tender.id} tender={tender} />
      ))}
    </Stack>
  );
}

const Answers = ({ tender }: { tender: BrTender }) => {
  const navigate = useNavigate();
  return (
    <Stack spacing="02">
      <Group
        noWrap
        spacing="02"
        px="03"
        py="02"
        sx={theme => ({
          borderRadius: theme.radius.md,
          ':hover': {
            cursor: 'pointer',
            background: theme.colors.gray[0],
          },
        })}
        onClick={() => navigate(`/reponse/${tender.id}?tab=simulation`)}
      >
        <TenderTitle buyerOriginalName={tender.buyerName} tenderTitle={tender.title} />
        <TenderStatusBadge status={tender.status} />
        <Text variant="sm" fw={400} c="gray.9">
          {tender.responseDeadline}
        </Text>
      </Group>
      <Divider color="gray.1" />
    </Stack>
  );
};

const EmptyPlaceholder = () => {
  const [opened, setOpened] = useState(false);
  return (
    <Box
      h="100%"
      mx="05"
      bg="background: linear-gradient(180deg, #F7FBFF 34%, rgba(247, 251, 255, 0) 100%)"
      sx={theme => ({ borderRadius: theme.radius.md })}
    >
      <CreateBrTenderModal opened={opened} onClose={() => setOpened(false)} />
      <Stack align="center" spacing="05" m="10%">
        <Image
          src={EmptyToAnalyseIllustration}
          alt="Illustration de liste d'appels d'offre vide"
          maw="116px"
          mah="116px"
        />
        <Stack spacing="02" align="center">
          <Title align="center" order={4} c="gray.9" maw="480px">
            Vous n'avez pas d'opportunités à analyser pour l'instant
          </Title>
          <Text variant="sm" fw={400} c="gray.3" align="center" maw="80%">
            Ajoutez de nouveaux appels d'offres pour les analyser ici.
          </Text>
        </Stack>
        <Button
          variant="filled"
          color="primary"
          size="lg"
          rightIcon={<IconArrowRight />}
          onClick={() => setOpened(true)}
        >
          Ajouter un appel d'offres
        </Button>
      </Stack>
    </Box>
  );
};
