import { useSuspenseQuery } from '@tanstack/react-query';

import { getBrTenders } from '../../api/magellan';

export function useAnswers() {
  const queryKey = [getBrTenders.name];
  const queryFn = () => getBrTenders();
  const { data: brTenders } = useSuspenseQuery({
    queryKey,
    queryFn,
  });
  return {
    tenders: brTenders,
  };
}
