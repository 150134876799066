import * as reactRouterDom from 'react-router-dom';
import { Suspense, useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { AppShell } from '@mantine/core';
import { modals } from '@mantine/modals';

import * as Sentry from '@sentry/react';
import { IconBallpen } from '@tabler/icons-react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Crisp } from 'crisp-sdk-web';
import { SuperTokensWrapper } from 'supertokens-auth-react';
import { EmailPasswordPreBuiltUI } from 'supertokens-auth-react/recipe/emailpassword/prebuiltui';
import { SessionAuth, useSessionContext } from 'supertokens-auth-react/recipe/session';
import { getSuperTokensRoutesForReactRouterDom } from 'supertokens-auth-react/ui';

import { Navbar } from './UI/Navbar/Navbar.tsx';

import * as superTokens from './infra/supertokens.ts';
import { queryClient } from './api/queryClient.ts';
import { CustomLoader } from './components/CustomLoader.tsx';
import ErrorComponent from './components/ErrorComponent.tsx';
import FeatureFlagsAndActiveUserProvider, {
  useActiveUser,
} from './components/FeatureFlagsAndActiveUser.context.tsx';
import { ThemeProvider } from './components/ThemeProvider.tsx';
import ModalsProvider from './components/modals/ModalsProvider.tsx';
import './infra/crisp.ts';
import './infra/hotjar.ts';
import { AnswerPage } from './pages/Answer.page.tsx';
import { AnswersPage } from './pages/Answers.page.tsx';
import PageNotFound from './pages/PageNotFound.tsx';

const { BrowserRouter, Routes, Route } = reactRouterDom;

superTokens.init();

export default function App() {
  return (
    <ThemeProvider>
      <QueryClientProvider client={queryClient}>
        <SuperTokensWrapper>
          <FeatureFlagsAndActiveUserProvider>
            <BrowserRouter>
              <ModalsProvider>
                <Routes>
                  {getSuperTokensRoutesForReactRouterDom(reactRouterDom, [EmailPasswordPreBuiltUI])}
                  <Route
                    path="/"
                    element={
                      <SessionAuth>
                        <AppWithLogin />
                      </SessionAuth>
                    }
                  >
                    {/* HOME PAGE */}
                    <Route path="/" element={<Navigate to={`/reponse`} />} />

                    {/* ANSWERS PAGES */}
                    <Route path="/reponse" element={<AnswersPage />} />
                    <Route path="/reponse/:id" element={<AnswerPage />} />
                  </Route>
                  <Route path="*" element={<PageNotFound />} />
                </Routes>
              </ModalsProvider>
            </BrowserRouter>
          </FeatureFlagsAndActiveUserProvider>
        </SuperTokensWrapper>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ThemeProvider>
  );
}

function AppWithLogin() {
  const session = useSessionContext();
  const { activeUser } = useActiveUser();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const onboarding = searchParams.get('onboarding');

  useEffect(() => {
    if (!session.loading && session.doesSessionExist) {
      if (import.meta.env.PROD) {
        const email = session.accessTokenPayload.email;

        heap.identify(session.userId);
        heap.addUserProperties({ email });
        Crisp.user.setEmail(email);
        // we cannot identify on hotjar, that's a paying feature}
      }
    }
  }, [session]);

  useEffect(() => {
    if (onboarding === 'true') {
      openOnboardingModal();
    }
  }, [onboarding]);

  const openOnboardingModal = () => {
    modals.openContextModal({
      fullScreen: true,
      withCloseButton: true,
      modal: 'onboarding',
      transitionProps: { transition: 'slide-up', duration: 300, timingFunction: 'linear' },
      innerProps: { step: 0 },
    });
  };

  return (
    <AppShell
      padding={0}
      header={
        <Navbar
          links={[
            {
              link: '/reponse',
              label: 'Aide à la réponse',
              icon: <IconBallpen size={16} />,
            },
          ]}
          user={activeUser}
        />
      }
    >
      <Sentry.ErrorBoundary fallback={<ErrorComponent />}>
        <Suspense fallback={<CustomLoader title="chargement" />}>
          <Outlet />
        </Suspense>
      </Sentry.ErrorBoundary>
    </AppShell>
  );
}
