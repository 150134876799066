import { Badge, Text } from '@mantine/core';

import type { TenderStatus } from '../../entities/Tender';

interface TenderStatusBadgeProps {
  status?: TenderStatus;
  size?: 'sm' | 'md' | 'lg';
}

export default function TenderStatusBadge({ status, size = 'md' }: TenderStatusBadgeProps) {
  if (!status) {
    return null;
  }

  let color: string;
  let label: string;

  switch (status) {
    case 'OPEN': {
      color = 'teal.2';
      label = 'Ouvert';
      break;
    }

    case 'CLOSED': {
      color = 'primary.2';
      label = 'Fermé';
      break;
    }

    default: {
      throw new Error(`Unknown status: ${status}`);
    }
  }

  return (
    <Badge size={size} radius="lg" p="2px 10px 2px 10px" bg={color}>
      <Text variant="xs" fw="600" color="gray.9" sx={{ textTransform: 'capitalize' }}>
        {label}
      </Text>
    </Badge>
  );
}
