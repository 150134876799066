import React, { useEffect, useState } from 'react';

import { Stack, Text, Group, Table, useMantineTheme, Menu, Skeleton } from '@mantine/core';

import { IconCheck, IconChevronDown, IconMistOff } from '@tabler/icons-react';

import { Button } from '../../UI/Button/Button';

import type { BRUserRetrievalAssessment } from '../../entities/BrBuyerEvaluation';
import { BrUserSubmissionAssessment } from '../../entities/BrBuyerEvaluation';
import { type BrCriteria } from '../../entities/BrBuyerEvaluation';
import { SourceButton } from '../SourceButton';
import { SourcesContent } from '../SourcesContent';
import { AssessmentDropdown } from '../notices/InstantAnalysis/AssessmentDropdown';
import { useRetrievalAssessment } from './useRetrievalAssessment.hook';

type EvalGridProps = {
  brTenderId: number;
  criterias: BrCriteria[];
};

export const EvalGrid = ({ brTenderId, criterias }: EvalGridProps) => {
  const categories = criterias?.map(criteria => criteria.category);
  const uniqueCategories = categories ? Array.from(new Set(categories)) : [];
  let offset = 0;

  return (
    <Stack
      spacing="05"
      sx={theme => ({
        borderRadius: theme.radius.md,
        border: `1px solid ${theme.colors.gray[1]}`,
        background: `linear-gradient(0deg, #FFFFFF, #FFFFFF),linear-gradient(86.95deg, rgba(90, 91, 111, 0.06) 0%, rgba(255, 255, 255, 0.06) 6.18%);
`,
      })}
    >
      <Stack spacing="0">
        <Table highlightOnHover verticalSpacing="sm">
          <CriteraTable
            fileName={''}
            criterias={undefined}
            withHeader
            offset={0}
            brTenderId={brTenderId}
          />
        </Table>
        {uniqueCategories.map((category, index) => {
          const evalCriterias = criterias?.filter(criteria => criteria.category === category);
          const prevOffset = offset;
          offset += evalCriterias?.length || 0;
          return (
            <CriteraTable
              key={index}
              fileName={category}
              criterias={evalCriterias}
              offset={prevOffset}
              brTenderId={brTenderId}
            />
          );
        })}
      </Stack>
    </Stack>
  );
};

type TableProps = {
  fileName: string;
  criterias: BrCriteria[] | undefined;
  offset: number;
  withHeader?: boolean;
  brTenderId: number;
};

const CriteraTable = ({ fileName, offset, criterias, withHeader, brTenderId }: TableProps) => {
  const numberColumnWidth = '4%';
  const BuyerExpectationWidth = '31%';
  const OfferElementWidth = '55%';
  const EvaluationWidth = '10%';

  if (withHeader) {
    return (
      <thead>
        <HeaderRow
          numberColWidth={numberColumnWidth}
          buyerExpectationWidth={BuyerExpectationWidth}
          offerElementWidth={OfferElementWidth}
          evaluationWidth={EvaluationWidth}
        />
      </thead>
    );
  }
  return (
    <Stack spacing="0">
      <Text
        variant="md"
        fw={600}
        c="gray.7"
        w="100%"
        p="03"
        sx={theme => ({
          background: theme.colors.gray[1],
          verticalAlign: 'center',
          borderBottom: `1px solid ${theme.colors.gray[2]}`,
          borderTop: `1px solid ${theme.colors.gray[2]}`,
        })}
      >
        {fileName.split('/').pop()}
      </Text>
      <Table verticalSpacing="sm">
        <tbody>
          {criterias?.map((criteria, index) => (
            <Row
              key={criteria.id}
              critera={criteria}
              index={index + offset + 1}
              numberColWidth={numberColumnWidth}
              buyerExpectationWidth={BuyerExpectationWidth}
              offerElementWidth={OfferElementWidth}
              evaluationWidth={EvaluationWidth}
              brTenderId={brTenderId}
            />
          ))}
        </tbody>
      </Table>
    </Stack>
  );
};

type RowProps = {
  numberColWidth: string;
  buyerExpectationWidth: string;
  offerElementWidth: string;
  evaluationWidth: string;
};

const HeaderRow = ({
  numberColWidth,
  buyerExpectationWidth,
  offerElementWidth,
  evaluationWidth,
}: RowProps) => {
  return (
    <tr
      style={{
        borderBottom: 'none',
      }}
    >
      <th style={{ width: numberColWidth, borderBottom: 'none' }}>
        <Text variant="xs" fw={500} c="gray.6" style={{}}>
          #
        </Text>
      </th>
      <th style={{ width: buyerExpectationWidth, borderBottom: 'none' }}>
        <Text variant="xs" fw={500} c="gray.6">
          Attentes de l'acheteur
        </Text>
      </th>
      <th style={{ width: offerElementWidth, borderBottom: 'none' }}>
        <Text variant="xs" fw={500} c="gray.6">
          Élements de l'offre
        </Text>
      </th>
      <th style={{ width: evaluationWidth, borderBottom: 'none' }}>
        <Text variant="xs" fw={500} c="gray.6">
          Évaluation
        </Text>
      </th>
    </tr>
  );
};

const Row = ({
  critera,
  index,
  numberColWidth,
  buyerExpectationWidth,
  offerElementWidth,
  evaluationWidth,
  brTenderId,
}: RowProps & { critera: BrCriteria; index: number; brTenderId: number }) => {
  const theme = useMantineTheme();

  const rowStyle = {
    verticalAlign: 'top',
    padding: theme.spacing['03'],
    borderTopColor: theme.colors.gray[1],
  };
  const extratedFromTechnicalSubmission = critera.retrievedFromTechnicalSubmission
    ?.split('\n')
    .map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  return (
    <tr
      style={{
        borderTopColor: theme.colors.red[2],
      }}
    >
      <th style={{ ...rowStyle, width: numberColWidth }}>
        <Text variant="sm" fw={400} c="gray.5">
          {index}
        </Text>
      </th>
      <th style={{ ...rowStyle, width: buyerExpectationWidth }}>
        <Group noWrap spacing="02" align="flex-start">
          <Text variant="sm" fw={500} c="gray.6">
            {critera.description}
          </Text>
          <SourceButton
            source={{
              docFilePath: critera.source,
              pageNumber: critera.pageNumber,
              originalChunkExtract: critera.originalChunkExtract,
            }}
            key={critera.id}
          />
        </Group>
      </th>
      <th style={{ ...rowStyle, width: offerElementWidth }}>
        <Stack>
          <Group noWrap>
            <Text variant="sm" fw={500} c="gray.6">
              {critera.retrievedFromTechnicalSubmission ? (
                extratedFromTechnicalSubmission
              ) : (
                <Skeleton w="400px" h="8px" />
              )}
            </Text>
            {(critera.userRetrievalAssessment === 'PRESENT_BUT_NOT_FOUND' ||
              critera.userRetrievalAssessment === 'NOT_PRESENT') && (
              <RetrivialDropdown
                criteraId={critera.evaluationId}
                brTenderId={brTenderId}
                initialAssessment={critera.userRetrievalAssessment}
              />
            )}
          </Group>
          {critera.userRetrievalAssessment === 'UNVERIFIED' && (
            <RetrivialButtons criteraId={critera.evaluationId} brTenderId={brTenderId} />
          )}
          {critera.userRetrievalAssessment === 'ASSESSMENT_NOT_ALLOWED' && (
            <SourcesContent
              sources={critera.technicalSubmissionSources || []}
              label="MT"
              key={critera.id}
            />
          )}
        </Stack>
      </th>
      <th style={{ ...rowStyle, width: evaluationWidth }}>
        {critera.retrievedFromTechnicalSubmission ? (
          <AssessmentElement
            assessment={critera.userSubmissionAssessment}
            criteraId={critera.evaluationId}
            brTenderId={brTenderId}
          />
        ) : (
          <Skeleton w="80px" h="8px" />
        )}
      </th>
    </tr>
  );
};

const RetrivialButtons = ({ criteraId, brTenderId }: { criteraId: number; brTenderId: number }) => {
  const theme = useMantineTheme();
  const userRetrievalAssessmentMutation = useRetrievalAssessment(brTenderId);

  return (
    <Group>
      <Button
        variant="light"
        size="sm"
        color="gray"
        leftIcon={<IconCheck color={theme.colors.primary[5]} />}
        onClick={() =>
          userRetrievalAssessmentMutation.mutate({
            criteraId,
            userRetrievalAssessment: 'PRESENT_BUT_NOT_FOUND',
          })
        }
      >
        L’information est dans le memtech
      </Button>
      <Button
        variant="light"
        size="sm"
        color="gray"
        leftIcon={<IconMistOff color={theme.colors.red[6]} />}
        onClick={() =>
          userRetrievalAssessmentMutation.mutate({
            criteraId,
            userRetrievalAssessment: 'NOT_PRESENT',
          })
        }
      >
        L’information est manquante
      </Button>
    </Group>
  );
};

const RetrivialDropdown = ({
  criteraId,
  brTenderId,
  initialAssessment,
}: {
  criteraId: number;
  brTenderId: number;
  initialAssessment: BRUserRetrievalAssessment;
}) => {
  const theme = useMantineTheme();
  const notFoundIcon = <IconCheck color={theme.colors.primary[5]} size={14} />;
  const notPresentIcon = <IconMistOff color={theme.colors.red[6]} size={14} />;
  const userRetrievalAssessmentMutation = useRetrievalAssessment(brTenderId);
  const [value, setValue] = useState<BRUserRetrievalAssessment>(initialAssessment);

  const onChange = (value: BRUserRetrievalAssessment) => {
    setValue(value);
    userRetrievalAssessmentMutation.mutate({
      criteraId,
      userRetrievalAssessment: value,
    });
  };

  return (
    <Menu shadow="md" withinPortal position="bottom-end">
      <Menu.Target>
        <Group
          noWrap
          h="06"
          p="02"
          spacing="02"
          onMouseDown={e => e.stopPropagation()}
          onClick={e => e.stopPropagation()}
          sx={theme => ({
            background:
              value === 'PRESENT_BUT_NOT_FOUND' ? theme.colors.primary[1] : theme.colors.red[1],
            color:
              value === 'PRESENT_BUT_NOT_FOUND' ? theme.colors.primary[5] : theme.colors.red[6],
            borderRadius: theme.radius.md,
            ':hover': {
              cursor: 'pointer',
              background: theme.colors.gray[2],
            },
          })}
        >
          {value === 'PRESENT_BUT_NOT_FOUND' ? notFoundIcon : notPresentIcon}
          <IconChevronDown size={16} />
        </Group>
      </Menu.Target>

      <Menu.Dropdown onMouseDown={e => e.stopPropagation()} onClick={e => e.stopPropagation()}>
        <Menu.Item
          onMouseDown={e => e.stopPropagation()}
          onClick={() => onChange('PRESENT_BUT_NOT_FOUND')}
        >
          <Group spacing="02">
            {notFoundIcon}
            <Text>L’information est dans le memtech</Text>
          </Group>
        </Menu.Item>
        <Menu.Item onMouseDown={e => e.stopPropagation()} onClick={() => onChange('NOT_PRESENT')}>
          <Group spacing="02">
            {notPresentIcon}
            <Text>L’information est manquante</Text>
          </Group>
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

const data = [
  {
    value: BrUserSubmissionAssessment.TO_EVALUATE,
    label: 'À évaluer',
    assessment: BrUserSubmissionAssessment.TO_EVALUATE,
  },
  {
    value: BrUserSubmissionAssessment.TO_ADD,
    label: 'À ajouter',
    assessment: BrUserSubmissionAssessment.TO_ADD,
  },
  {
    value: BrUserSubmissionAssessment.TO_REWORK,
    label: 'À retravailler',
    assessment: BrUserSubmissionAssessment.TO_REWORK,
  },
  {
    value: BrUserSubmissionAssessment.SATISFYING,
    label: 'Satisfaisant',
    assessment: BrUserSubmissionAssessment.SATISFYING,
  },
];

const AssessmentElement = ({
  assessment,
  brTenderId,
  criteraId,
}: {
  assessment: BrUserSubmissionAssessment;
  brTenderId: number;
  criteraId: number;
}) => {
  const [value, setValue] = useState<BrUserSubmissionAssessment>(assessment);
  const userRetrievalAssessmentMutation = useRetrievalAssessment(brTenderId);
  useEffect(() => {
    setValue(assessment);
  }, [assessment]);

  const onSelect = (assesment: BrUserSubmissionAssessment | null) => {
    if (!assesment) {
      return;
    }
    setValue(assesment);
    userRetrievalAssessmentMutation.mutate({
      criteraId,
      userSubmissionAssessment: assesment,
    });
  };

  return (
    <AssessmentDropdown withoutBorder data={data} selectedAssesment={value} onSelect={onSelect} />
  );
};
