import { useState } from 'react';

import { Badge, Group, Title } from '@mantine/core';

import { IconPlus } from '@tabler/icons-react';

import { Button } from '../../UI/Button/Button';

import { CreateBrTenderModal } from './CreateBrTenderModal';

export function AnswersHeader() {
  const [opened, setOpened] = useState(false);

  return (
    <Group position="apart">
      <CreateBrTenderModal opened={opened} onClose={() => setOpened(false)} />
      <Group spacing="0">
        <Title order={3}>Aide à la réponse</Title>
        <Badge color="primary">BETA</Badge>
      </Group>
      <Button leftIcon={<IconPlus />} onClick={() => setOpened(true)}>
        Ajouter un appel d'offres
      </Button>
    </Group>
  );
}
