import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import type { ModalProps } from '@mantine/core';
import { Stack, Title, Group, ThemeIcon, Text, LoadingOverlay, Modal } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import type { FileWithPath } from '@mantine/dropzone';
import { Dropzone } from '@mantine/dropzone';
import { useForm } from '@mantine/form';

import { IconAsterisk } from '@tabler/icons-react';

import { Button } from '../../UI/Button/Button';
import { TextInput } from '../../UI/TextInput/TextInput';

import { createBrTender } from '../../api/magellan';

type CreateBrTenderModalProps = ModalProps;
export const CreateBrTenderModal = ({ opened, onClose }: CreateBrTenderModalProps) => {
  const [files, setFiles] = useState<FileWithPath[]>([]);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const navigate = useNavigate();

  const handleDrop = (newFile: FileWithPath[]) => {
    setFiles([...files, ...newFile]);
  };

  const form = useForm({
    initialValues: {
      title: '',
      buyerName: '',
      responseDeadlineDate: undefined,
    },
    validate: {
      title: (value: string) => (value.trim().length > 0 ? null : 'Champ requis'),
      buyerName: (value: string) => (value.trim().length > 0 ? null : 'Champ requis'),
      responseDeadlineDate: value => (value === undefined ? 'Champ requis' : null),
    },
  });
  return (
    <Modal opened={opened} onClose={onClose} withCloseButton={false}>
      <form>
        <Stack p="05" spacing="05">
          <LoadingOverlay visible={isOverlayVisible} overlayBlur={2} />
          <Title order={5}>Ajouter un appel d’offres</Title>
          <TextInput label="Nom de l'acheteur" required {...form.getInputProps('buyerName')} />
          <TextInput label="Titre de l'appel d'offres" required {...form.getInputProps('title')} />
          <Stack spacing="02">
            <Group spacing="01">
              <Text weight={500} size="sm" lh="20px">
                Date limite de submission
              </Text>
              <ThemeIcon
                radius="100%"
                size="03"
                variant="light"
                sx={theme => ({
                  backgroundColor: theme.colors.primary[1],
                  color: theme.colors.primary[6],
                })}
              >
                <IconAsterisk size={8} stroke={3} />
              </ThemeIcon>
            </Group>
            <DateInput placeholder="JJ/MM/AAA" {...form.getInputProps('responseDeadlineDate')} />
            <DropZoneInput files={files} onFileChange={handleDrop} />
          </Stack>
          <Group position="right">
            <Button id="buttonSkip" variant="subtle" c="primary.9" onClick={onClose}>
              Annuler
            </Button>
            <Button
              maw="25%"
              miw="200px"
              radius="md"
              size="md"
              color="primary"
              type="submit"
              onClick={async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                e.preventDefault();

                if (!form.validate().hasErrors && form.values.responseDeadlineDate) {
                  setIsOverlayVisible(true);
                  const res = await createBrTender({
                    title: form.values.title,
                    buyerName: form.values.buyerName,
                    responseDeadlineDate: form.values.responseDeadlineDate,
                    files,
                  });
                  if (res) {
                    navigate(`/reponse/${res.id}?tab=simulation`);
                  }
                }
              }}
            >
              Créer l'appel d'offre
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  );
};

type DropZoneInputProps = {
  files: FileWithPath[];
  onFileChange: (files: FileWithPath[]) => void;
};

const DropZoneInput = ({ files, onFileChange }: DropZoneInputProps) => {
  return (
    <Stack spacing="02">
      <Group noWrap spacing="01">
        <Text weight={500} size="sm" lh="20px">
          DCE de l'appel d'offres
        </Text>
        <ThemeIcon
          radius="100%"
          size="03"
          variant="light"
          sx={theme => ({
            backgroundColor: theme.colors.primary[1],
            color: theme.colors.primary[6],
          })}
        >
          <IconAsterisk size={8} stroke={3} />
        </ThemeIcon>
      </Group>
      <Dropzone onDrop={onFileChange}>
        <Stack spacing="02">
          <Text variant="sm" c="gray.6">
            Glissez et déposez ici le DCE de l'appel d'offres
          </Text>
          <Button variant="subtle" color="primary">
            Parcourir
          </Button>
        </Stack>
      </Dropzone>
      {files.map((file, index) => (
        <Text key={index}>{file.name}</Text>
      ))}
    </Stack>
  );
};
