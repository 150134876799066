import type { FileWithPath } from '@mantine/dropzone';

import * as Sentry from '@sentry/react';
import axios from 'axios';

import type { AssessmentStatus } from '../entities/AssessmentStatus';
import type {
  BRUserRetrievalAssessment,
  BrUserSubmissionAssessment,
} from '../entities/BrBuyerEvaluation';
import type { BrTender } from '../entities/BrTender';
import type Buyer from '../entities/Buyer';
import type { BuyerContact, MinimalBuyerDTO } from '../entities/Buyer';
import type { Cpv } from '../entities/Cpv';
import type { FrenchDepartment } from '../entities/FrenchDepartment';
import type { InstantAnalysisAnswer } from '../entities/InstantAnalysisAnswer';
import type { InstantAnalysisQuestion } from '../entities/InstantAnalysisQuestion';
import type { Interaction } from '../entities/Interaction';
import { DecisionStatus } from '../entities/Interaction';
import type { QuestionsSet } from '../entities/QuestionsSet';
import type Stream from '../entities/Stream';
import type { Filters, StreamFilterSettings } from '../entities/StreamFilterSettings';
import type { StreamSection } from '../entities/StreamSection';
import type Tender from '../entities/Tender';
import type { User } from '../entities/User';
import type { TenderWithListData } from '../pages/DecisionList.page';
import type {
  CreateStreamSectionResponseDTO,
  GetActiveUserResponseDto,
  GetAllStreamsResponseDTO,
  GetAssessmentsRecapResponseDTO,
  GetBrAssessmentsRecapResponseDTO,
  GetCompanyQuestionsResponseDTO,
  GetOnTheFlyInstantAnalysisResponseDTO,
  GetQuestionsSetInstantAnalysisResponseDTO,
  IsInstantAnalysisAnalysableResponseDTO,
  SearchTenderDTO,
  UpdateOwnerResponseDTO,
} from './magellan.dto';

const instance = axios.create({
  baseURL: import.meta.env.VITE_MAGELLAN_BASE_URL,
});

instance.interceptors.response.use(
  response => {
    if (response.status === 400 || response.status === 404) {
      throw new Error('Not found');
    }
    return response;
  },
  error => {
    Sentry.captureException(error, {
      tags: {
        section: error.config ? error.config.url : 'unknown', // Using the URL as a tag to be searchable in Sentry
      },
      extra: {
        request: {
          url: error.config?.url,
          method: error.config?.method,
          data: error.config?.data,
          headers: error.config?.headers,
        },
        response: {
          status: error.response?.status,
          data: error.response?.data,
          headers: error.response?.headers,
        },
      },
    });

    return Promise.reject(error);
  },
);

export async function getBuyersByName(buyerName: string) {
  const response = await instance.get(`/buyers?name=${buyerName}`);
  return response.data as MinimalBuyerDTO[];
}

export async function getBuyerById(id: string) {
  const response = await instance.get(`/buyers/${id}`);
  return response.data as Buyer;
}

export async function getDepartments(): Promise<FrenchDepartment[]> {
  const response = await instance.get('/departments');
  return response.data as FrenchDepartment[];
}

export async function getCpvByCode(code: string) {
  const response = await instance.get(`/cpv/${code}`);
  return response.data as Cpv;
}

export async function searchCPV(search: string) {
  const response = await instance.get(`/cpv?search=${search}&take=100`);
  return response.data as Cpv[];
}

export async function getTender(id: string) {
  const response = await instance.get(`/tenders/${id}`);
  return response.data as Tender;
}

export async function getTenderForBuyer(
  buyerId: number,
  skip: number,
  take: number,
  streamIds: string[],
) {
  const response = await instance.post(`/buyers/${buyerId}/tenders`, {
    streamIds: streamIds.map(id => parseInt(id, 10)),
    skip,
    take,
  });

  return {
    tenders: response.data.tenders as Tender[],
    pagination: {
      skip: response.data.pagination.skip as number,
      take: response.data.pagination.take as number,
      totalResults: response.data.pagination.totalResults as number,
    },
  };
}

export async function getContactsForBuyer(buyerId: number) {
  const response = await instance.get(`/buyers/${buyerId}/contacts`);
  return response.data as BuyerContact[];
}

export async function searchTenders(
  filter: Filters,
  skip: number,
  take: number,
  withDecision: boolean,
): Promise<SearchTenderDTO> {
  const streamFilterSettings: StreamFilterSettings = {
    ...filter,
    skip,
    take,
    withDecision,
  };
  const response = await instance.post('/tenders/search', streamFilterSettings);
  return response.data as SearchTenderDTO;
}

export async function searchTendersPreview(streamFilterSettings: Filters) {
  const response = await instance.post('/tenders/searchPreview', streamFilterSettings);
  return response.data.count as number;
}

export async function createStream(stream: Stream) {
  const response = await instance.post('/streams', stream);
  return response.data as Stream;
}

export async function updateStream(id: string, stream: Stream) {
  const response = await instance.put(`/streams/${id}`, stream);
  return response.data as Stream;
}

export async function deleteStream(id: number) {
  const response = await instance.delete(`/streams/${id}`);
  return response.data as Stream;
}

export async function getStreams() {
  const response = await instance.get('/streams');
  return response.data as GetAllStreamsResponseDTO;
}

export async function getStream(id: number): Promise<Stream> {
  const response = await instance.get(`/streams/${id}`);
  const {
    afterPublicationDate,
    beforePublicationDate,
    afterResponseDeadlineDate,
    beforeResponseDeadlineDate,
  } = response.data.filterSettings;

  const stream = {
    ...response.data,
    filterSettings: {
      ...response.data.filterSettings,
      afterPublicationDate: afterPublicationDate ? new Date(afterPublicationDate) : null,
      beforePublicationDate: beforePublicationDate ? new Date(beforePublicationDate) : null,
      afterResponseDeadlineDate: afterResponseDeadlineDate
        ? new Date(afterResponseDeadlineDate)
        : null,
      beforeResponseDeadlineDate: beforeResponseDeadlineDate
        ? new Date(beforeResponseDeadlineDate)
        : null,
    },
  };

  return stream;
}

export async function upsertInteractionWithDecisionStatus(
  tenderId: number,
  status: DecisionStatus,
): Promise<Interaction> {
  const response = await instance.post(`tenders/${tenderId}/interactions/decisionStatus`, {
    status,
  });

  return response.data as Interaction;
}

export async function upsertInteractionWithNogo(
  tenderId: number,
  reason: string,
): Promise<Interaction> {
  const response = await instance.post(`tenders/${tenderId}/interactions/nogo`, {
    reason,
  });

  return response.data as Interaction;
}

export async function upsertInteractionWithResults(
  tenderId: number,
  reason: string,
  decisionStatus: DecisionStatus.WIN | DecisionStatus.LOSS,
): Promise<Interaction> {
  const response = await instance.post(`tenders/${tenderId}/interactions/result`, {
    decisionStatus,
    reason,
  });

  return response.data as Interaction;
}

export async function upsertInteractionWihNote(tenderId: number, note: string) {
  const response = await instance.post(`tenders/${tenderId}/interactions/note`, {
    note,
  });

  return response.data;
}

export async function updateInteractionOwner(
  userId: string,
  tenderId: number,
): Promise<UpdateOwnerResponseDTO> {
  const response = await instance.post(`tenders/${tenderId}/interactions/owner`, {
    userId,
  });
  return response.data as UpdateOwnerResponseDTO;
}

export async function getTendersWithInteraction(status?: DecisionStatus) {
  const response = await instance.get(`/interactions`, {
    params: {
      status: status,
      orderBy: status === DecisionStatus.ANSWERED ? 'answeredAt' : undefined,
    },
  });

  return response.data as TenderWithListData[];
}

export async function getDecisionCounts() {
  const response = await instance.get(`/interactions/counts`);

  return response.data.counts;
}

export async function getUsers(): Promise<User[]> {
  const response = await instance.get(`/users`);

  return response.data as User[];
}

export async function getTenderIsAnalysable(
  tenderId: number,
): Promise<IsInstantAnalysisAnalysableResponseDTO> {
  const response = await instance.get(`/tenders/${tenderId}/instantAnalysis/isAnalyzable`);

  return response.data as IsInstantAnalysisAnalysableResponseDTO;
}

export async function getQuestionsSetsInstantAnalysis(tenderId: number) {
  const response = await instance.get(`/tenders/${tenderId}/instantAnalysisAnswers/questionsSets`);

  return response.data as GetQuestionsSetInstantAnalysisResponseDTO;
}

export async function getOnTheFlyInstantAnalysis(tenderId: number) {
  const response = await instance.get(
    `/tenders/${tenderId}/instantAnalysisAnswers/onTheFlyQuestions`,
  );

  return response.data as GetOnTheFlyInstantAnalysisResponseDTO;
}

export async function getCompanyQuestions() {
  const response = await instance.get(`/instantAnalysisQuestions`);

  return response.data as GetCompanyQuestionsResponseDTO;
}

export async function createQuestion(question: string, questionsSetId: number) {
  const response = await instance.post(`/instantAnalysisQuestions`, {
    question,
    questionsSetId,
  });

  return response.data as InstantAnalysisQuestion;
}

export async function deleteQuestion(questionId: number) {
  const response = await instance.delete(`/instantAnalysisQuestions/${questionId}`);

  return response.data;
}

export async function requestDCE(tenderId: number) {
  const response = await instance.post(`/tenders/${tenderId}/interactions/dceRequest`);

  return response.data;
}

export async function getOnboardingFlag(): Promise<boolean> {
  const { data } = await instance.get('/users/hasSeenOnboarding');
  return data.hasSeenOnboarding as boolean;
}

export async function setOnboardingDone(status: boolean) {
  await instance.post('/users/hasSeenOnboarding', {
    hasSeenOnboarding: status,
  });
}

export async function setOnboardingFlag(hasSeenOnboarding: boolean): Promise<boolean> {
  const res = await instance.post('/users/hasSeenOnboarding', { hasSeenOnboarding });
  return res.data as boolean;
}

export async function getAllQuestionsSets(): Promise<QuestionsSet[]> {
  const response = await instance.get(`/questionsSets`);

  return response.data as QuestionsSet[];
}

export async function createQuestionsSet(
  title: string,
  questions: string[],
): Promise<QuestionsSet> {
  const response = await instance.post(`/questionsSets`, {
    title,
    questions,
  });

  return response.data as QuestionsSet;
}

export async function updateQuestionsSet(title: string, id: number) {
  const response = await instance.put(`/questionsSets/${id}`, {
    title,
  });

  return response.data;
}

export async function softDeleteQuestionsSet(id: number) {
  const response = await instance.delete(`/questionsSets/${id}`);

  return response.data;
}

export async function triggerInstantAnalysisWithQuestionsSets(
  tenderId: number,
  questionsSetsIds: number[],
) {
  try {
    const response = await instance.post(`/tenders/${tenderId}/instantAnalysis/trigger`, {
      questionsSetsIds,
    });
    return response.status;
  } catch (e: any) {
    if (e.response.status === 422) {
      return false;
    } else {
      throw e;
    }
  }
}

export async function triggerOnTheFlyQuestionAnalysis(tenderId: number, question: string) {
  try {
    const response = await instance.post(
      `/tenders/${tenderId}/instantAnalysis/triggerOnTheFlyQuestion`,
      {
        question,
      },
    );
    return response.status;
  } catch (e: any) {
    if (e.response.status === 422) {
      return false;
    } else {
      throw e;
    }
  }
}

export async function getStreamSections() {
  const response = await instance.get(`/streamSections`);
  return response.data as StreamSection[];
}

export async function createStreamSection(title: string) {
  const response = await instance.post(`/streamSections`, {
    title,
  });
  return response.data as CreateStreamSectionResponseDTO;
}

export async function updateStreamSection(id: number, title: string) {
  const response = await instance.put(`/streamSections/${id}`, {
    title,
  });
  return response.data;
}

export async function deleteStreamSection(id: number) {
  const response = await instance.delete(`/streamSections/${id}`);
  return response.data;
}

export async function getActiveUser() {
  const response = await instance.get(`/users/me`);
  return response.data as GetActiveUserResponseDto;
}

export async function markAsFavorite(streamId: number): Promise<void> {
  await instance.post(`/users/${streamId}/favorite`);
}

export async function unmarkAsFavorite(streamId: number): Promise<void> {
  await instance.delete(`/users/${streamId}/favorite`);
}

export async function assessInstantAnalysisAnswer(
  answerId: number,
  assessment: AssessmentStatus | null,
) {
  await instance.put(`/instantAnalysisAnswers/${answerId}/assessment`, {
    assessment: assessment,
  });
}

export async function getAssessmentsRecap(
  tenderId: number,
): Promise<GetAssessmentsRecapResponseDTO> {
  const response = await instance.get(
    `/tenders/${tenderId}/instantAnalysisAnswers/assessmentsRecap`,
  );

  return response.data;
}

export async function updateAiRelevanceFeedback(
  answerId: number,
  aiRelevanceFeedback: string | null,
): Promise<void> {
  await instance.put(`/instantAnalysisAnswers/${answerId}/aiRelevanceFeedback`, {
    feedback: aiRelevanceFeedback,
  });
}

export async function updateManualIaAnswer(
  answerId: number,
  userAnswer: string,
): Promise<InstantAnalysisAnswer> {
  const updatedanswer = await instance.put(`/instantAnalysisAnswers/${answerId}/userAnswer`, {
    userAnswer: userAnswer.length ? userAnswer : null,
  });
  return updatedanswer.data;
}

export async function deleteBuyerLogoURL(buyerId: number) {
  await instance.put(`/buyers/${buyerId}/logo`);
}

export async function getBrTenders() {
  const response = await instance.get(`/brTenders`);
  return response.data as BrTender[];
}

export async function getBrTender(id: number) {
  const response = await instance.get(`/brTenders/${id}`);
  return response.data as BrTender;
}

type createBrTenderProps = {
  title: string;
  buyerName: string;
  responseDeadlineDate: Date;
  files: FileWithPath[];
};
export async function createBrTender({
  title,
  buyerName,
  responseDeadlineDate,
  files,
}: createBrTenderProps) {
  const formData = new FormData();

  Object.entries({
    title,
    buyerName,
    responseDeadlineDate: responseDeadlineDate.toISOString(),
  }).forEach(([key, value]: [string, any]) => {
    formData.append(key, value);
  });

  files.forEach(file => {
    formData.append(`dceFiles`, file);
  });

  const response = await instance.post(`/brTenders`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data as BrTender;
}

export async function createTechnicalSubmission(brTenderId: number, file: FileWithPath) {
  const formData = new FormData();

  formData.append(`tsFile`, file);

  const response = await instance.post(`/brTenders/${brTenderId}/submission`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data as BrTender;
}

export async function updateUserRetrievalAssessment(
  brTenderId: number,
  criteriaId: number,
  assessment: {
    userRetrievalAssessment?: BRUserRetrievalAssessment;
    userSubmissionAssessment?: BrUserSubmissionAssessment;
  },
) {
  const res = await instance.put(
    `/brTenders/${brTenderId}/brCriteriaEvaluations/${criteriaId}`,
    assessment,
  );
  return res.data;
}

export async function getBrAssessmentsRecap(brTenderId: number) {
  const response = await instance.get(
    `/brTenders/${brTenderId}/brCriteriaEvaluations/assessmentsRecap`,
  );
  return response.data as GetBrAssessmentsRecapResponseDTO;
}
