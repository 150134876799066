import type { ReactNode } from 'react';
import { createContext, useContext, useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';

import { getActiveUser } from '../api/magellan';
import type { FeatureFlag, FeatureFlagsType } from '../entities/FeatureFlags';
import type { User } from '../entities/User';

type FeatureFlagsContextType = {
  isFeatureEnabled: (feature: FeatureFlag) => boolean;
};

type ActiveUserContextType = {
  activeUser?: User;
};

export const FeatureFlagsContext = createContext<FeatureFlagsContextType | null>(null);
export const ActiveUserContext = createContext<ActiveUserContextType | null>(null);

export default function FeatureFlagsAndActiveUserProvider({ children }: { children: ReactNode }) {
  const session = useSessionContext();
  const [featureFlags, setFeatureFlags] = useState<FeatureFlagsType | null>(null);
  const [activeUser, setActiveUser] = useState<User>();
  const isSessionActive = !session.loading && session.doesSessionExist;

  const isFeatureEnabled = (feature: FeatureFlag) => {
    return featureFlags?.[feature] ?? false;
  };

  const { data, isError, isLoading } = useQuery({
    queryKey: [getActiveUser.name],
    queryFn: getActiveUser,
    enabled: isSessionActive,
  });

  useEffect(() => {
    if (data?.featureFlags) {
      setFeatureFlags(data.featureFlags);
    }
    if (data?.user) {
      setActiveUser(data.user);
    }
  }, [data?.featureFlags, data?.user, isSessionActive]);

  if (isError || isLoading) {
    return null;
  }

  return (
    <ActiveUserContext.Provider value={{ activeUser }}>
      <FeatureFlagsContext.Provider value={{ isFeatureEnabled }}>
        {children}
      </FeatureFlagsContext.Provider>
    </ActiveUserContext.Provider>
  );
}

export const useFeatureFlags = (feature: FeatureFlag) => {
  const context = useContext(FeatureFlagsContext);

  if (!context) {
    throw new Error('useFeatureFlagContext must be used within a FeatureFlagsProvider');
  }
  return context.isFeatureEnabled(feature);
};

export const useActiveUser = (): { activeUser: User } => {
  const context = useContext(ActiveUserContext);

  if (!context) {
    throw new Error('useActiveUserContext must be used within a ActiveUserProvider');
  }
  if (!context.activeUser) {
    throw new Error('No active user found');
  }

  return { activeUser: context.activeUser };
};
