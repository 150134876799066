import type { ReactNode } from 'react';
import React, { Suspense } from 'react';

import { Group, Tabs as MantineTabs, Text } from '@mantine/core';

import * as Sentry from '@sentry/react';

import { CustomLoader } from '../../components/CustomLoader';
import ErrorComponent from '../../components/ErrorComponent';
import { withFeatureFlag } from '../../components/FeatureFlagGuard';
import type { FeatureFlag } from '../../entities/FeatureFlags';
import { Counter } from '../Counter/Counter';
import { TabsProvider, useTabsContext } from './TabsProvider.context';
import { useTabs } from './useTabs.hook';

export type TabsProps<TabValue extends string> = React.ComponentProps<typeof MantineTabs> & {
  children: React.ReactNode;
  defaultTab: TabValue;
};

const TABS_HEIGHT = 34;

export function TabsComponents<TabsValue extends string>() {
  function Tabs({ defaultTab, children, onTabChange, mx = '05', ...rest }: TabsProps<TabsValue>) {
    const { currentTab, handleTabChange, tabButtons, tabContents } = useTabs<TabsValue>(
      defaultTab,
      children,
    );

    return (
      <TabsProvider defaultTab={defaultTab}>
        <MantineTabs
          keepMounted={false}
          w="100%"
          h="100%"
          defaultValue={defaultTab}
          value={currentTab}
          onTabChange={(value: TabsValue) => {
            onTabChange && onTabChange(value);
            handleTabChange(value);
          }}
          {...rest}
        >
          <MantineTabs.List
            mx={mx}
            sx={theme => ({ borderBottom: `1px solid ${theme.colors.gray[2]}` })}
          >
            {tabButtons}
          </MantineTabs.List>
          {tabContents}
        </MantineTabs>
      </TabsProvider>
    );
  }

  type TabButtonProps<TabsValue extends string> = {
    value: TabsValue;
    icon?: ReactNode;
    label: ReactNode | string;
    counter?: number;
    featureFlag?: FeatureFlag;
  };

  function TabButton({ value, label, icon, featureFlag, counter }: TabButtonProps<TabsValue>) {
    const { currentTab } = useTabsContext<TabsValue>();
    const isSelected = currentTab === value;

    const content = (
      <MantineTabs.Tab value={value}>
        <Group spacing="xs">
          {icon}
          <Text variant="sm" fw={isSelected ? '500' : '400'} c={isSelected ? 'gray.9' : 'gray.6'}>
            {label}
          </Text>
          {counter && (
            <Counter variant="subtle" value={counter} color={isSelected ? 'primary' : 'gray'} />
          )}
        </Group>
      </MantineTabs.Tab>
    );
    return withFeatureFlag(content, featureFlag);
  }

  type TabContentProps<TabsValue extends string> = {
    children: ReactNode;
    value: TabsValue;
    loadingComponent?: ReactNode;
  };

  function TabContent({ loadingComponent, children, value }: TabContentProps<TabsValue>) {
    return (
      <MantineTabs.Panel value={value} w="100%" h={`calc(100% - ${TABS_HEIGHT}px)`}>
        <Sentry.ErrorBoundary fallback={<ErrorComponent />}>
          <Suspense fallback={loadingComponent || <CustomLoader title="Chargement..." />}>
            {children}
          </Suspense>
        </Sentry.ErrorBoundary>
      </MantineTabs.Panel>
    );
  }

  TabButton.displayName = 'TabButton';
  TabContent.displayName = 'TabContent';
  Tabs.Button = TabButton;
  Tabs.Content = TabContent;
  return Tabs;
}
