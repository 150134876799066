import { Group } from '@mantine/core';

import { TenderTitle } from '../../UI/TenderTitle/TenderTitle';

type AnswerHeaderProps = {
  title: string;
  buyerName: string;
};

export function AnswerHeader({ title, buyerName }: AnswerHeaderProps) {
  return (
    <Group>
      <TenderTitle tenderTitle={title} buyerOriginalName={buyerName} size="lg" />
    </Group>
  );
}
