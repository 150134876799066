import React from 'react';

import { Group, Popover, Stack, Text, ThemeIcon, Title } from '@mantine/core';

import { IconEye } from '@tabler/icons-react';

import type { DataPointSource } from '../entities/DataPointSource';

export const SourcesContent = ({
  sources,
  label,
}: {
  sources: DataPointSource[];
  label?: string;
}) => {
  return (
    <Stack spacing={4}>
      <Text variant="xs" fw={500} c="gray.4">
        Source
      </Text>
      <Group spacing="xxs">
        {sources.map((source, index) => (
          <Popover
            key={`${source.originalChunkExtract.substring(0, 50)}+${index}`}
            closeOnEscape
            shadow="md"
            position="bottom"
          >
            <Popover.Target>
              <Group
                maw={264}
                p="5px 12px"
                key={source.docFilePath + (source.pageNumber ? source.pageNumber : '') + index}
                noWrap
                spacing="xs"
                sx={theme => ({
                  borderRadius: theme.radius.md,
                  border: `1px solid ${theme.colors.gray[1]}`,
                  ':hover': {
                    backgroundColor: theme.colors.gray[0],
                    cursor: 'pointer',
                  },
                })}
              >
                <Text variant="sm" fw={400} c="gray.6" truncate sx={{ ':hover': {} }}>
                  {source.pageNumber
                    ? `${label ?? source.docFilePath} - Page ${source.pageNumber}`
                    : label ?? source.docFilePath}
                </Text>
                <ThemeIcon size={16} color="gray.6" variant="outline" sx={{ border: 'none' }}>
                  <IconEye />
                </ThemeIcon>
              </Group>
            </Popover.Target>
            <Popover.Dropdown
              p="lg"
              sx={{
                maxWidth: '50%',
                ':hover': {
                  curser: 'pointer',
                },
              }}
            >
              <Stack spacing="md">
                <Stack spacing={0}>
                  <Title order={5}>{source.docFilePath}</Title>
                  {source.pageNumber && (
                    <Text variant="sm" fw={400} c="gray.6">
                      {`Page ${source.pageNumber}`}
                    </Text>
                  )}
                </Stack>
                <Text size="sm" color="gray.9">
                  {source.originalChunkExtract.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
                </Text>
              </Stack>
            </Popover.Dropdown>
          </Popover>
        ))}
      </Group>
    </Stack>
  );
};
