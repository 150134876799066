import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import type { TabsValue } from '@mantine/core';
import { Group, Stack, Text } from '@mantine/core';

import { ErrorBoundary } from '@sentry/react';
import {
  IconArrowLeft,
  IconBlockquote,
  IconCheckupList,
  IconDragDrop,
  IconEyeglass,
} from '@tabler/icons-react';

import { TabsComponents } from '../UI/Tabs/Tabs';

import { AnswerHeader } from '../components/Answer/AnswerHeader';
import { BuyerSimulationTab } from '../components/Answer/BuyerSimulationTab';
import { useAnswer } from '../components/Answer/useAnswer.hook';
import EmptyPlaceholder from '../components/EmptyPlaceholder';
import ErrorComponent from '../components/ErrorComponent';
import { useSetPageTitle } from '../hooks/useSetPageTitle';

type PageParams = {
  id: string;
};

type TabValues = 'coordination' | 'redaction' | 'simulation' | 'checklist';
export function AnswerPage() {
  useSetPageTitle('Réponse');
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const urlTabsValue = queryParams.get('tab') as TabsValue | null;
  const defaultTab = 'simulation';
  const [tabValue, setTabValue] = React.useState<TabsValue>(urlTabsValue || defaultTab);
  const { id } = useParams<PageParams>() as PageParams; // Casting to enforce type. See https://github.com/remix-run/react-router/issues/8498
  const tender = useAnswer(Number(id));

  const Tabs = TabsComponents<TabValues>();
  return (
    <Stack
      spacing="05"
      w="100%"
      py="05"
      px="06"
      sx={{
        background: `linear-gradient(180deg, #EFF8FF -5.79%, #FFFFFF 19.49%)`,
      }}
    >
      <ReturnButton />
      <Stack
        py="05"
        px="06"
        spacing="02"
        sx={theme => ({
          background: 'white',
          borderRadius: theme.radius.md,
          border: `1px solid ${theme.colors.gray[9]}14`,
        })}
      >
        <AnswerHeader title={tender.title} buyerName={tender.buyerName} />
        <Tabs
          defaultTab={defaultTab}
          value={tabValue}
          onTabChange={(value: TabsValue) => setTabValue(value)}
          mx={0}
        >
          <Tabs.Button
            value="coordination"
            icon={<IconDragDrop size={14} stroke={2} />}
            label="Coordination"
          />
          <Tabs.Button
            value="redaction"
            icon={<IconBlockquote size={14} stroke={2} />}
            label="Rédation"
          />
          <Tabs.Button
            value="simulation"
            icon={<IconEyeglass size={14} stroke={2} />}
            label="Simulation acheteur"
          />
          <Tabs.Button
            value="checklist"
            icon={<IconCheckupList size={14} stroke={2} />}
            label="Conformité"
          />
        </Tabs>
        {tabValue !== 'simulation' ? (
          <EmptyPlaceholder title="À venir" />
        ) : (
          <ErrorBoundary fallback={<ErrorComponent />}>
            <BuyerSimulationTab
              brTenderId={Number(id)}
              extractionStatus={tender.criteriasExtractionStatus}
              buyerEval={tender.brBuyerEvaluation}
              memTechs={tender.submissions}
            />
          </ErrorBoundary>
        )}
      </Stack>
    </Stack>
  );
}

const ReturnButton = () => {
  const navigate = useNavigate();
  return (
    <Group
      c="gray.6"
      spacing="02"
      onClick={() => navigate('/reponse')}
      sx={theme => ({
        ':hover': {
          cursor: 'pointer',
          color: theme.colors.gray[8],
        },
      })}
    >
      <IconArrowLeft size={16} />
      <Text variant="sm" fw={400}>
        Retour - Aide à la réponse
      </Text>
    </Group>
  );
};
