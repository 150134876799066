import { useCallback, useEffect, useState } from 'react';

import { Stack, Title, Text, Group, Radio, FileButton } from '@mantine/core';
import { Dropzone } from '@mantine/dropzone';
import type { FileWithPath } from '@mantine/dropzone';

import { IconInfoCircle, IconPaperclip, IconPlus } from '@tabler/icons-react';

import { Button } from '../../UI/Button/Button';

import type { BrUserSubmissionAssessment, BrCriteria } from '../../entities/BrBuyerEvaluation';
import { BrAnalysisStatus } from '../../entities/BrBuyerEvaluation';
import { type BrBuyerEvaluation } from '../../entities/BrBuyerEvaluation';
import type { BrDoc } from '../../entities/BrDocs';
import { DceLoader } from '../Answers/DceLoader';
import { EvalCard } from './EvalCard';
import { EvalGrid } from './EvalGrid';
import { useCreateTechnicalSubmission } from './useCreateTechnicalSubmission.hook';

type BuyerSimulationTabProps = {
  buyerEval: BrBuyerEvaluation[];
  brTenderId: number;
  memTechs: BrDoc[];
  extractionStatus: BrAnalysisStatus;
};

export function BuyerSimulationTab({
  brTenderId,
  buyerEval,
  memTechs,
  extractionStatus,
}: BuyerSimulationTabProps) {
  const [filter, setFilter] = useState<BrUserSubmissionAssessment | null>(null);
  const [memTechId, setMemTechId] = useState(memTechs[0]?.id);
  const evalToDisplay = buyerEval?.find(e => e.docs.find(b => b.id === memTechId));
  const [filteredCritera, setFilteredCriteria] = useState<BrCriteria[]>(
    evalToDisplay?.criterias || [],
  );
  const isBuyerEvalFailed = buyerEval.some(e => e.brAnalysisStatus === BrAnalysisStatus.FAILED);

  if (extractionStatus === BrAnalysisStatus.FAILED || isBuyerEvalFailed) {
    throw new Error('Error');
  }

  const handleFilterChange = useCallback((filter: BrUserSubmissionAssessment | null) => {
    setFilter(filter);
  }, []);

  useEffect(() => {
    setMemTechId(memTechs[memTechs.length - 1]?.id);
  }, [memTechs]);

  useEffect(() => {
    const filtered =
      evalToDisplay?.criterias.filter(criteria => {
        if (filter === null) {
          return true;
        }
        return criteria.userSubmissionAssessment === filter;
      }) || [];
    setFilteredCriteria(filtered);
  }, [evalToDisplay, filter]);

  if (!evalToDisplay) {
    return (
      <EmptyMemtechInput
        brTenderId={brTenderId}
        isGenerationDone={extractionStatus === BrAnalysisStatus.DONE}
      />
    );
  }

  return (
    <Stack spacing="06" p="06">
      <MemtechInput brTenderId={brTenderId} memTechs={memTechs} changeVersion={setMemTechId} />

      <Stack
        px="06"
        py="05"
        sx={theme => ({
          borderRadius: theme.radius.md,
          border: `1px solid ${theme.colors.gray[1]}`,
          background: `linear-gradient(0deg, #FFFFFF, #FFFFFF),linear-gradient(86.95deg, rgba(90, 91, 111, 0.06) 0%, rgba(255, 255, 255, 0.06) 6.18%);
`,
        })}
      >
        <Stack spacing="0" px="03">
          <Title order={4}>Grille d'analyse estimée de l'acheteur</Title>
          <Text variant="sm" fw={400} c="gray.5">
            Découvrer si votre mémoire technique correspond aux attentes de l’acheteur
          </Text>
        </Stack>
        <EvalCard brTenderId={brTenderId} filter={filter} setFilter={handleFilterChange} />
        <EvalGrid criterias={filteredCritera} brTenderId={brTenderId} />
      </Stack>
    </Stack>
  );
}

type EmptyMemtechInputProps = {
  brTenderId: number;
  isGenerationDone: boolean;
};

const EmptyMemtechInput = ({ brTenderId, isGenerationDone }: EmptyMemtechInputProps) => {
  const techSumbissionMutation = useCreateTechnicalSubmission();
  const handleMemTech = async (files: FileWithPath[]) => {
    techSumbissionMutation.mutate({ brTenderId, file: files[0] });
  };

  return (
    <Stack
      px="06"
      py="05"
      h="190px"
      w="100%"
      spacing="05"
      sx={theme => ({
        background: `linear-gradient(180deg, #F7F7F8 0%, rgba(255, 255, 255, 0) 100%)`,
        borderRadius: theme.radius.md,
      })}
    >
      <Stack spacing="00">
        <Title order={4}>Évaluer un mémoire technique</Title>
        <Text variant="sm" fw={400} c="gray.5">
          Challenger sur vos mémoires techniques par notre intelligence artificielle entrainée sur
          les meilleures pratiques du marché
        </Text>
      </Stack>
      {isGenerationDone ? (
        <Dropzone
          maxFiles={1}
          onDrop={handleMemTech}
          w="fit-content"
          sx={theme => ({
            borderRadius: theme.radius.md,
            borderColor: theme.colors.gray[3],
          })}
          disabled={!isGenerationDone}
        >
          <Group spacing="02">
            <IconPaperclip size={16} />
            <Text>Chargez votre mémoire technique</Text>
            <IconInfoCircle size={10} />
          </Group>
          <Text variant="xs" fw={400} c="gray.6">
            Fichier zip, document word, powerpoint, excel ou pdf
          </Text>
        </Dropzone>
      ) : (
        <DceLoader />
      )}
    </Stack>
  );
};

type MemtechInputProps = {
  brTenderId: number;
  memTechs: BrDoc[];
  changeVersion: (memTechId: number) => void;
};

const MemtechInput = ({ brTenderId, memTechs, changeVersion }: MemtechInputProps) => {
  const lastMemTech = memTechs[memTechs.length - 1];
  const [value, setValue] = useState(lastMemTech.id);
  const techSumbissionMutation = useCreateTechnicalSubmission();
  const handleMemTech = async (file: File) => {
    techSumbissionMutation.mutate({ brTenderId, file });
  };

  useEffect(() => {
    changeVersion(value);
  }, [changeVersion, value]);

  return (
    <Stack
      px="06"
      py="05"
      spacing="05"
      sx={theme => ({
        background: `linear-gradient(180deg, #F7F7F8 0%, rgba(255, 255, 255, 0) 100%)`,
        borderRadius: theme.radius.md,
      })}
    >
      <Stack spacing="00">
        <Title order={4}>Évaluer un mémoire technique</Title>
        <Text variant="sm" fw={400} c="gray.5">
          Challenger sur vos mémoires techniques par notre intelligence artificielle entrainée sur
          les meilleures pratiques du marché
        </Text>
      </Stack>
      <Stack spacing="0">
        {memTechs.map((memTech, index) => (
          <Group
            w="fit-content"
            key={`${memTech.id}-${index}`}
            px="03"
            py="02"
            sx={theme => ({
              borderRadius: theme.radius.md,
              ':hover': {
                cursor: 'pointer',
                background: theme.colors.gray[0],
              },
            })}
            onClick={() => setValue(memTech.id)}
          >
            <Radio checked={value === memTech.id} value={memTech.id} onChange={() => null} />
            <RadioLabel fileName={memTech.fileName} versionNumber={index} />
          </Group>
        ))}
      </Stack>
      <FileButton onChange={handleMemTech}>
        {props => (
          <Button
            variant="light"
            size="sm"
            w="fit-content"
            color="gray"
            leftIcon={<IconPlus />}
            {...props}
          >
            Nouvelle version
          </Button>
        )}
      </FileButton>
    </Stack>
  );
};

type RadioLabelProps = {
  fileName: string;
  versionNumber: number;
};

const RadioLabel = ({ fileName, versionNumber }: RadioLabelProps) => {
  return (
    <Group spacing="03">
      <Text variant="xs" fw={400} c="gray.6">
        v{versionNumber}
      </Text>
      <Group
        p="02"
        spacing="02"
        sx={theme => ({
          borderRadius: theme.radius.md,
          border: `1px solid ${theme.colors.gray[1]}`,
        })}
      >
        <IconPaperclip size={16} />
        <Text>{fileName}</Text>
      </Group>
    </Group>
  );
};
